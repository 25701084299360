import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { ILargePhotoApi$reflection, Route_builder, LargePhotoUrlPack$reflection } from "../../Shared/Shared.fs.js";
import { union_type, record_type, int32_type, option_type, array_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { view as view_1, init as init_2, Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "./PhotosComponents/ThumbnailSelector.fs.js";
import { view as view_2, update as update_1, init as init_1, Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "./PhotosComponents/MediumDisplay.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "../.fable/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { Cmd_batch, Cmd_OfFunc_result, Cmd_map, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { value, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { map, mapIndexed } from "../.fable/fable-library.3.2.1/Array.js";
import { singleton, append, delay, toList, head } from "../.fable/fable-library.3.2.1/Seq.js";
import { ofArray } from "../.fable/fable-library.3.2.1/List.js";
import * as react from "react";
import { int32ToString } from "../.fable/fable-library.3.2.1/Util.js";

export class Model extends Record {
    constructor(Photos, ThumbnailSelectors, MediumDisplay, CurrentPhotoIndex, PreviousPhotoUrls) {
        super();
        this.Photos = Photos;
        this.ThumbnailSelectors = ThumbnailSelectors;
        this.MediumDisplay = MediumDisplay;
        this.CurrentPhotoIndex = CurrentPhotoIndex;
        this.PreviousPhotoUrls = PreviousPhotoUrls;
    }
}

export function Model$reflection() {
    return record_type("Client.Pages.Photos.Model", [], Model, () => [["Photos", array_type(LargePhotoUrlPack$reflection())], ["ThumbnailSelectors", array_type(Model$reflection_1())], ["MediumDisplay", option_type(Model$reflection_2())], ["CurrentPhotoIndex", option_type(int32_type)], ["PreviousPhotoUrls", option_type(LargePhotoUrlPack$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ReceivePhotos", "SwitchPhoto", "ThumbnailSelectorMsg", "MediumDisplayMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Pages.Photos.Msg", [], Msg, () => [[["Item", array_type(LargePhotoUrlPack$reflection())]], [["Item", int32_type]], [["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]]]);
}

export const largePhotosApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
    ResolveType: ILargePhotoApi$reflection,
});

export const init = [new Model([], [], void 0, void 0, void 0), Cmd_OfAsyncWith_perform((x) => {
    Cmd_OfAsync_start(x);
}, largePhotosApi.getPhotos, void 0, (arg0) => (new Msg(0, arg0)))];

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            const newIndex = msg.fields[0] | 0;
            const patternInput_1 = init_1(model.Photos[newIndex], model.Photos[defaultArg(model.CurrentPhotoIndex, 0)]);
            return [new Model(model.Photos, model.ThumbnailSelectors, patternInput_1[0], newIndex, model.Photos[defaultArg(model.CurrentPhotoIndex, 0)]), Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_1[1])];
        }
        case 2: {
            return [model, Cmd_OfFunc_result(new Msg(1, msg.fields[0].fields[0]))];
        }
        case 3: {
            const patternInput_2 = update_1(msg.fields[0], value(model.MediumDisplay));
            return [new Model(model.Photos, model.ThumbnailSelectors, patternInput_2[0], model.CurrentPhotoIndex, model.PreviousPhotoUrls), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_2[1])];
        }
        default: {
            const photos = msg.fields[0];
            const thumbnails = mapIndexed((index, urls) => init_2(urls, index)[0], photos);
            if (photos.length > 0) {
                const patternInput = init_1(head(photos), head(photos));
                return [new Model(photos, thumbnails, patternInput[0], model.CurrentPhotoIndex, model.PreviousPhotoUrls), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(1, 0)), Cmd_map((arg0) => (new Msg(3, arg0)), patternInput[1])]))];
            }
            else {
                return [new Model(photos, thumbnails, model.MediumDisplay, model.CurrentPhotoIndex, model.PreviousPhotoUrls), Cmd_OfFunc_result(new Msg(1, 0))];
            }
        }
    }
}

export function view(model, dispatch) {
    if (!(model.Photos.length === 0)) {
        return react.createElement("div", {}, ...toList(delay(() => append(singleton("Photo URLs loaded!"), delay(() => append(singleton(react.createElement("br", {})), delay(() => {
            let copyOfStruct;
            return append(singleton("Current selected index: " + (copyOfStruct = defaultArg(model.CurrentPhotoIndex, 0), int32ToString(copyOfStruct))), delay(() => append(singleton(react.createElement("div", {}, ...map((ts) => view_1(ts, (cmd) => {
                dispatch(new Msg(2, cmd));
            }), model.ThumbnailSelectors))), delay(() => ((model.MediumDisplay != null) ? singleton(view_2(value(model.MediumDisplay), (cmd_1) => {
                dispatch(new Msg(3, cmd_1));
            })) : singleton("No photo selected"))))));
        })))))));
    }
    else {
        return "Photos incoming!";
    }
}

