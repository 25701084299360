import { Union, Record } from "../../.fable/fable-library.3.2.1/Types.js";
import { LargePhotoUrlPack$reflection } from "../../../Shared/Shared.fs.js";
import { union_type, record_type, int32_type } from "../../.fable/fable-library.3.2.1/Reflection.js";
import { Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import * as react from "react";

export class Model extends Record {
    constructor(urls, index) {
        super();
        this.urls = urls;
        this.index = (index | 0);
    }
}

export function Model$reflection() {
    return record_type("Client.Pages.PhotosComponents.ThumbnailSelector.Model", [], Model, () => [["urls", LargePhotoUrlPack$reflection()], ["index", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserActivatesPicture"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Pages.PhotosComponents.ThumbnailSelector.Msg", [], Msg, () => [[["Item", int32_type]]]);
}

export function init(urls, index) {
    return [new Model(urls, index), Cmd_none()];
}

export function update(msg, model) {
    return [model, Cmd_none()];
}

export function view(model, dispatch) {
    return react.createElement("img", {
        src: model.urls.thumbnailUrl,
        onMouseOver: (_arg1) => {
            dispatch(new Msg(0, model.index));
        },
    });
}

