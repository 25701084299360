import { Union } from "../../fable-library.3.2.1/Types.js";
import { list_type, class_type, string_type, union_type } from "../../fable-library.3.2.1/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Screen_ToString_2D2414B4, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Screen$reflection, Reflection_getCaseName } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.2.1/MapUtil.js";
import { contains } from "../../fable-library.3.2.1/List.js";
import { stringHash } from "../../fable-library.3.2.1/Util.js";
import { printf, toText } from "../../fable-library.3.2.1/String.js";
import { some } from "../../fable-library.3.2.1/Option.js";

export class ISize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "is-7", "is-8"];
    }
}

export function ISize$reflection() {
    return union_type("Fulma.Columns.ISize", [], ISize, () => [[], [], [], [], [], [], [], []]);
}

export function ISize_ToString_2283FB3F(x) {
    return Reflection_getCaseName(x);
}

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-centered", "is-vcentered", "is-multiline", "is-gapless", "is-mobile", "is-desktop", "IsGap", "IsGapOnly", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Columns.Option", [], Option, () => [[], [], [], [], [], [], [["Item1", Screen$reflection()], ["Item2", ISize$reflection()]], [["Item1", Screen$reflection()], ["Item2", ISize$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function columns(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let screen_4, size_4, x_2, arg30, arg20, arg10, screen_5, size_5, x_3, arg30_1, arg20_1, arg10_1;
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                const size = option.fields[1];
                const screen = option.fields[0];
                if (!contains("is-variable", result.Classes, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: (x) => stringHash(x),
                })) {
                    return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__AddClass_Z721C83C5(result, "is-variable"), ISize_ToString_2283FB3F(size) + Screen_ToString_2D2414B4(screen));
                }
                else {
                    return Common_GenericOptions__AddClass_Z721C83C5(result, ISize_ToString_2283FB3F(size) + Screen_ToString_2D2414B4(screen));
                }
            }
            case 7: {
                const size_3 = option.fields[1];
                const screen_3 = option.fields[0];
                if (!contains("is-variable", result.Classes, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: (x_1) => stringHash(x_1),
                })) {
                    return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__AddClass_Z721C83C5(result, "is-variable"), (screen_4 = screen_3, (size_4 = size_3, (screen_4.tag === 2) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : ((screen_4.tag === 1) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : ((screen_4.tag === 4) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : (x_2 = screen_4, (console.warn(some((arg30 = Screen_ToString_2D2414B4(x_2), (arg20 = ISize_ToString_2283FB3F(size_4), (arg10 = Screen_ToString_2D2414B4(x_2), toText(printf("Screen `%s` does not support `is-%s-%s-only`."))(arg10)(arg20)(arg30)))))), "")))))));
                }
                else {
                    return Common_GenericOptions__AddClass_Z721C83C5(result, (screen_5 = screen_3, (size_5 = size_3, (screen_5.tag === 2) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : ((screen_5.tag === 1) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : ((screen_5.tag === 4) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : (x_3 = screen_5, (console.warn(some((arg30_1 = Screen_ToString_2D2414B4(x_3), (arg20_1 = ISize_ToString_2283FB3F(size_5), (arg10_1 = Screen_ToString_2D2414B4(x_3), toText(printf("Screen `%s` does not support `is-%s-%s-only`."))(arg10_1)(arg20_1)(arg30_1)))))), "")))))));
                }
            }
            case 9: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 10: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "columns"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

