import { container } from "../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.1/List.js";
import * as react from "react";
import { p } from "../.fable/Fulma.2.14.1/Elements/Heading.fs.js";

export const DonatePage = container(empty(), ofArray([react.createElement("br", {}), p(empty(), singleton("How to donate")), "We accept PayPal for the moment. If you wish to donate through other means, please keep an eye on this page!", react.createElement("br", {}), react.createElement("br", {}), react.createElement("form", {
    action: "https://www.paypal.com/cgi-bin/webscr",
    method: "post",
    target: "_top",
}, react.createElement("input", {
    name: "cmd",
    type: "hidden",
    value: "_s-xclick",
}), react.createElement("input", {
    name: "hosted_button_id",
    type: "hidden",
    value: "NFWXPPVWWWQAS",
}), react.createElement("input", {
    alt: "PayPal - The safer, easier way to pay online!",
    name: "submit",
    src: "https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif",
    type: "image",
    style: {
        border: 0,
    },
}), react.createElement("img", {
    alt: "",
    src: "https://www.paypalobjects.com/en_US/i/scr/pixel.gif",
    style: {
        width: 1,
        height: 1,
        border: 0,
    },
})), react.createElement("br", {}), "The status of Madagascar Educational Opportunities, Inc. as a section 501(c) public charity was\n            recognized by the U.S. Internal Revenue Service on May 20, 2014.  This mean that contributions to Madagascar\n            Educational Opportunities, Inc. are recognized by the IRS as tax deductible.  Madagascar Educational\n            Opportunities, Inc. will provide each contributor with an acknowledgement of the contribution.", react.createElement("br", {}), react.createElement("br", {}), "As a public charity, Madagascar Educational Opportunities, Inc. will be pleased to provide upon request a\n            copy of its most recent annual report to the IRS.  The taxpayer identification number of Madagascar\n            Educational Opportunities, Inc. is 46-3713059.  Please submit any request for information by an email\n            request to the Treasurer of Madagascar Educational Opportunities, Inc. at ", react.createElement("a", {
    href: "mailto:MadagascarOpportunities@gmail.com",
}, "MadagascarOpportunities@gmail.com"), "."]));

