import { body, head, Option, hero } from "../.fable/Fulma.2.14.1/Layouts/Hero.fs.js";
import { Modifier_IModifier, TextAlignment_Option, Screen, Color_IColor } from "../.fable/Fulma.2.14.1/Common.fs.js";
import { empty, ofArray, singleton } from "../.fable/fable-library.3.2.1/List.js";
import { container } from "../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { Option as Option_1, ISize, column } from "../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import * as react from "react";
import { Option as Option_2, p } from "../.fable/Fulma.2.14.1/Elements/Heading.fs.js";

export const HomePage = hero(ofArray([new Option(6, new Color_IColor(4)), new Option(4), new Option(8, singleton(["style", {
    backgroundSize: "cover",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center fixed",
}]))]), ofArray([head(empty(), singleton(container(empty(), singleton(column(ofArray([new Option_1(0, new Screen(0), new ISize(12)), new Option_1(1, new Screen(0), new ISize(6))]), ofArray([react.createElement("img", {
    src: "https://res.cloudinary.com/hhbln7b7g/image/upload/c_scale,w_800/v1482020360/FrontPageNarrow2016_adnpg9.jpg",
}), p(singleton(new Option_2(10, ofArray([new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0)), new Modifier_IModifier(1, new Color_IColor(0))]))), singleton("Welcome to Madagascar Educational Opportunities!")), react.createElement("br", {}), p(ofArray([new Option_2(6), new Option_2(10, ofArray([new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0)), new Modifier_IModifier(1, new Color_IColor(0))]))]), singleton("Mission: To make a high school education accessible to bright, impoverished and\n                                    hard-working students from rural areas of Madagascar."))])))))), body(empty(), empty())]));

