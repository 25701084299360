import * as react from "react";
import { h2, h6 } from "../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.1/List.js";
import { Option, container } from "../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { columns } from "../.fable/Fulma.2.14.1/Layouts/Columns.fs.js";
import { Option as Option_1, ISize, column } from "../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { Screen } from "../.fable/Fulma.2.14.1/Common.fs.js";
import { Option as Option_2, image } from "../.fable/Fulma.2.14.1/Elements/Image.fs.js";

export function paragraph(text) {
    return react.createElement("div", {}, text);
}

export function pictureCaption(text) {
    return h6(empty())(singleton(text));
}

export const ProgressPage = container(singleton(new Option(3, singleton(["style", {
    width: "80%",
}]))), ofArray([paragraph("Winter 2020-2021 Newsletter"), paragraph("Through COVID, Malagasy students persevere"), columns(empty(), singleton(column(ofArray([new Option_1(0, new Screen(1), new ISize(10)), new Option_1(0, new Screen(3), new ISize(16))]), ofArray([image(singleton(new Option_2(10)), singleton(react.createElement("img", {
    src: "https://meoimagecdn.azureedge.net/site-images/image9937604.png",
}))), pictureCaption("Beneficiaries at an extra lesson provided by MEO.")])))), paragraph("2020 Successes"), paragraph("Two terminale MEO students (Victor and Lovatiana) were among the top-performing students at Joël\n                Sylvain High School."), paragraph("All 12 of MEO\u0027s terminale students were successfully brought to Joël Sylvain High School for the\n                2019-2020 school year in order to more easily provide support and assist with exam preparation."), paragraph("All of MEO\u0027s 60 students remained in school for the entire year (no dropouts/withdrawals)."), paragraph("Over 80% of students were able to save some of their stipend. Some placed their money in VSLAs (village\n            savings and loans associations) or savings bank or with someone they trust. Others bought pigs or poultry\n            for breeding or invested in rice to re-sell."), paragraph("Grades of terminale students improved overall compared with the 2018-2019 school year."), columns(empty(), singleton(column(ofArray([new Option_1(0, new Screen(1), new ISize(10)), new Option_1(0, new Screen(3), new ISize(16))]), ofArray([image(empty(), singleton(react.createElement("img", {
    src: "https://meoimagecdn.azureedge.net/site-images/image9937601.png",
}))), pictureCaption("Two terminale students with top marks, Victor and Lovatiana.")])))), paragraph("Due to COVID-related restrictions, classes for all students in seconde and premier were cancelled from\n            April to July 2019. Classes only continued for terminale students preparing for final exams (inlcuding\n            the baccalaureate). School fees during that time were still paid in order to provide income for non-civil\n            servant teachers."), paragraph("President Rajoelina extended the school year for two additional months and moved all final exam\n            dates to September and October 2020, meaning that additional tuition and stipends for September and October\n            had to be provided for all of MEO\u0027s terminale students."), paragraph("School fees increased from 10,000 to 15,000ar/month (approximately USD 4/month) at Vohitsaoka High\n            School (attended by 1 MEO student)."), columns(empty(), singleton(column(ofArray([new Option_1(0, new Screen(1), new ISize(10)), new Option_1(0, new Screen(3), new ISize(16))]), singleton(image(empty(), singleton(react.createElement("img", {
    src: "https://meoimagecdn.azureedge.net/site-images/image9937655.png",
}))))))), paragraph("Joséphine lives in Tsikahoe Anjoma. She is in premiere (second year) at Tsianimparihy Anjoma High School.\n            She doesn\u0027t have a father and her particularly difficult living conditions were part of why MEO accepted\n            her application. Her mother (shown here) died on November 30th, 2019."), react.createElement("br", {}), h2(empty())(singleton("Recommendations for 2021")), paragraph("Unless MEO\u0027s income increases, it is expected that only 10 new students will be funded; this is because of\n            increased costs associated with the extended school year, additional support lessons needed, and increases\n            in school fees."), paragraph("Impact on Students"), paragraph("MEO\u0027s annual youth camps were cancelled due to restrictions on large gatherings."), paragraph("Very frequent absence of teachers and the absence of the principal at Andrainjato High School (attended by\n            1 MEO student) made learning even more difficult."), paragraph("Some schools, such as Mahazony High School (attended by 4 MEO students), had significant delays in\n            providing student reports and grades."), paragraph("Due to the economic impact of COVID, some students had to do \"sarakatsaha\" (little jobs) on weekends to\n            earn money."), paragraph("Average per capita income of Madagascar is $260.00, and those living in rural areas make significantly\n            less."), paragraph("Since 2013, MEO has been helping rural students in the Haute Matsiatra region of Madagascar complete their\n            secondary education.")]));

