import { container } from "../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.1/List.js";
import * as react from "react";
import { Option, p } from "../.fable/Fulma.2.14.1/Elements/Heading.fs.js";

export const ContactPage = container(empty(), ofArray([react.createElement("br", {}), p(empty(), singleton("Contact Us!")), p(singleton(new Option(6)), singleton("Please feel free to contact us with any questions you have.")), "Email: ", react.createElement("a", {
    href: "mailto:MadagascarOpportunities@gmail.com",
}, "MadagascarOpportunities@gmail.com"), react.createElement("br", {}), "Phone: (706) 340-5150", react.createElement("br", {}), "Facebook: ", react.createElement("a", {
    href: "https://www.facebook.com/mad.opportunities",
}, "https://www.facebook.com/mad.opportunities"), react.createElement("br", {}), "Mailing Address:", react.createElement("div", {
    style: {
        marginLeft: "40px",
    },
}, "Madagascar Educational Opportunities, Inc. ", react.createElement("br", {}), "P.O. Box 1811", react.createElement("br", {}), "Woodland Park, Colorado, 80866", react.createElement("br", {}), "USA")]));

