import { Union, Record } from "./.fable/fable-library.3.2.1/Types.js";
import { TodoModule_isValid, TodoModule_create, ITodosApi$reflection, Route_builder, Todo$reflection } from "../Shared/Shared.fs.js";
import { union_type, record_type, string_type, list_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { view as view_1, update as update_1, init as init_1, Msg$reflection as Msg$reflection_1, Model as Model_1, Model$reflection as Model$reflection_1 } from "./MeoNavBar.fs.js";
import { view as view_2, update as update_2, init as init_2, Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "./Pages/Photos.fs.js";
import { Page, Page$reflection } from "./Page.fs.js";
import { oneOf, s as s_2, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { singleton, append, empty, ofArray } from "./.fable/fable-library.3.2.1/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform, Cmd_map, Cmd_batch, Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Navigation_modifyUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./.fable/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { box$0027 } from "./.fable/Fulma.2.14.1/Elements/Box.fs.js";
import { Ol_ol, content } from "./.fable/Fulma.2.14.1/Elements/Content.fs.js";
import { singleton as singleton_1, append as append_1, map as map_1, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";
import * as react from "react";
import { Option, div } from "./.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { Option as Option_1, p } from "./.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { Option as Option_2, IInputType, input } from "./.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { Option as Option_3, a } from "./.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { Color_IColor } from "./.fable/Fulma.2.14.1/Common.fs.js";
import { AboutPage } from "./Pages/About.fs.js";
import { ProgressPage } from "./Pages/Progress.fs.js";
import { DonatePage } from "./Pages/Donate.fs.js";
import { ContactPage } from "./Pages/Contact.fs.js";
import { HomePage } from "./Pages/Home.fs.js";

export class Model extends Record {
    constructor(Todos, Input, MeoNavbarModel, PhotosModel, CurrentPage) {
        super();
        this.Todos = Todos;
        this.Input = Input;
        this.MeoNavbarModel = MeoNavbarModel;
        this.PhotosModel = PhotosModel;
        this.CurrentPage = CurrentPage;
    }
}

export function Model$reflection() {
    return record_type("Index.Model", [], Model, () => [["Todos", list_type(Todo$reflection())], ["Input", string_type], ["MeoNavbarModel", Model$reflection_1()], ["PhotosModel", Model$reflection_2()], ["CurrentPage", Page$reflection()]]);
}

export function toPage(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "#/about";
        }
        case 2: {
            return "#/photos";
        }
        case 3: {
            return "#/progress";
        }
        case 4: {
            return "#/donate";
        }
        case 5: {
            return "#/contact";
        }
        default: {
            return "#/home";
        }
    }
}

export const pageParser = (() => {
    const parsers = ofArray([map(new Page(0), s_2("/")), map(new Page(0), s_2("home")), map(new Page(1), s_2("about")), map(new Page(2), s_2("photos")), map(new Page(3), s_2("progress")), map(new Page(4), s_2("donate")), map(new Page(5), s_2("contact"))]);
    return (state) => oneOf(parsers, state);
})();

export function urlUpdate(result, model) {
    if (result != null) {
        const page = result;
        return [new Model(model.Todos, model.Input, new Model_1(model.MeoNavbarModel.MobileExpanded, page), model.PhotosModel, page), Cmd_none()];
    }
    else {
        return [model, Navigation_modifyUrl(toPage(model.CurrentPage))];
    }
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GotTodos", "SetInput", "AddTodo", "AddedTodo", "MeoNavBarMsg", "PhotosMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Index.Msg", [], Msg, () => [[["Item", list_type(Todo$reflection())]], [["Item", string_type]], [], [["Item", Todo$reflection()]], [["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]]]);
}

export const todosApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
    ResolveType: ITodosApi$reflection,
});

export function init(result) {
    const patternInput = init_1(new Page(0));
    const patternInput_1 = urlUpdate(result, new Model(empty(), "", patternInput[0], init_2[0], new Page(0)));
    return [patternInput_1[0], Cmd_batch(ofArray([patternInput_1[1], Cmd_map((arg0) => (new Msg(4, arg0)), patternInput[1]), Cmd_map((arg0_1) => (new Msg(5, arg0_1)), init_2[1])]))];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            return [new Model(model.Todos, msg.fields[0], model.MeoNavbarModel, model.PhotosModel, model.CurrentPage), Cmd_none()];
        }
        case 2: {
            return [new Model(model.Todos, "", model.MeoNavbarModel, model.PhotosModel, model.CurrentPage), Cmd_OfAsyncWith_perform((x) => {
                Cmd_OfAsync_start(x);
            }, todosApi.addTodo, TodoModule_create(model.Input), (arg0) => (new Msg(3, arg0)))];
        }
        case 3: {
            return [new Model(append(model.Todos, singleton(msg.fields[0])), model.Input, model.MeoNavbarModel, model.PhotosModel, model.CurrentPage), Cmd_none()];
        }
        case 4: {
            const patternInput = update_1(msg.fields[0], model.MeoNavbarModel);
            return [new Model(model.Todos, model.Input, patternInput[0], model.PhotosModel, model.CurrentPage), Cmd_map((arg0_1) => (new Msg(4, arg0_1)), patternInput[1])];
        }
        case 5: {
            const patternInput_1 = update_2(msg.fields[0], model.PhotosModel);
            return [new Model(model.Todos, model.Input, model.MeoNavbarModel, patternInput_1[0], model.CurrentPage), Cmd_map((arg0_2) => (new Msg(5, arg0_2)), patternInput_1[1])];
        }
        default: {
            return [new Model(msg.fields[0], model.Input, model.MeoNavbarModel, model.PhotosModel, model.CurrentPage), Cmd_none()];
        }
    }
}

export function containerBox(model, dispatch) {
    return box$0027(empty(), ofArray([content(empty(), singleton(Ol_ol(empty(), toList(delay(() => map_1((todo) => react.createElement("li", {}, todo.Description), model.Todos)))))), div(singleton(new Option(4)), ofArray([p(singleton(new Option_1(3)), singleton(input(ofArray([new Option_2(1, new IInputType(0)), new Option_2(8, model.Input), new Option_2(12, "What needs to be done?"), new Option_2(13, (x) => {
        dispatch(new Msg(1, Browser_Types_Event__Event_get_Value(x)));
    })])))), p(empty(), singleton(a(ofArray([new Option_3(0, new Color_IColor(4)), new Option_3(16, !TodoModule_isValid(model.Input)), new Option_3(18, (_arg1) => {
        dispatch(new Msg(2));
    })]), singleton("Add"))))]))]));
}

export function view(model, dispatch) {
    return react.createElement("div", {}, ...toList(delay(() => append_1(singleton_1(view_1(model.MeoNavbarModel, (cmd) => {
        dispatch(new Msg(4, cmd));
    })), delay(() => {
        const matchValue = model.CurrentPage;
        switch (matchValue.tag) {
            case 1: {
                return singleton_1(AboutPage);
            }
            case 2: {
                return singleton_1(view_2(model.PhotosModel, (cmd_1) => {
                    dispatch(new Msg(5, cmd_1));
                }));
            }
            case 3: {
                return singleton_1(ProgressPage);
            }
            case 4: {
                return singleton_1(DonatePage);
            }
            case 5: {
                return singleton_1(ContactPage);
            }
            default: {
                return singleton_1(HomePage);
            }
        }
    })))));
}

