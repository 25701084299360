import { Record } from "../Client/.fable/fable-library.3.2.1/Types.js";
import { array_type, lambda_type, list_type, unit_type, record_type, string_type, class_type } from "../Client/.fable/fable-library.3.2.1/Reflection.js";
import { printf, toText, isNullOrWhiteSpace } from "../Client/.fable/fable-library.3.2.1/String.js";
import { newGuid } from "../Client/.fable/fable-library.3.2.1/Guid.js";

export class Todo extends Record {
    constructor(Id, Description) {
        super();
        this.Id = Id;
        this.Description = Description;
    }
}

export function Todo$reflection() {
    return record_type("Shared.Todo", [], Todo, () => [["Id", class_type("System.Guid")], ["Description", string_type]]);
}

export function TodoModule_isValid(description) {
    return !isNullOrWhiteSpace(description);
}

export function TodoModule_create(description) {
    return new Todo(newGuid(), description);
}

export function Route_builder(typeName, methodName) {
    return toText(printf("/api/%s/%s"))(typeName)(methodName);
}

export class ITodosApi extends Record {
    constructor(getTodos, addTodo) {
        super();
        this.getTodos = getTodos;
        this.addTodo = addTodo;
    }
}

export function ITodosApi$reflection() {
    return record_type("Shared.ITodosApi", [], ITodosApi, () => [["getTodos", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(Todo$reflection())]))], ["addTodo", lambda_type(Todo$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Todo$reflection()]))]]);
}

export class LargePhotoUrlPack extends Record {
    constructor(thumbnailUrl, mediumUrl, fullUrl) {
        super();
        this.thumbnailUrl = thumbnailUrl;
        this.mediumUrl = mediumUrl;
        this.fullUrl = fullUrl;
    }
}

export function LargePhotoUrlPack$reflection() {
    return record_type("Shared.LargePhotoUrlPack", [], LargePhotoUrlPack, () => [["thumbnailUrl", string_type], ["mediumUrl", string_type], ["fullUrl", string_type]]);
}

export class ILargePhotoApi extends Record {
    constructor(getPhotos) {
        super();
        this.getPhotos = getPhotos;
    }
}

export function ILargePhotoApi$reflection() {
    return record_type("Shared.ILargePhotoApi", [], ILargePhotoApi, () => [["getPhotos", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(LargePhotoUrlPack$reflection())]))]]);
}

