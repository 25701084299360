import { Union } from "./.fable/fable-library.3.2.1/Types.js";
import { union_type } from "./.fable/fable-library.3.2.1/Reflection.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "About", "Photos", "Progress", "Donate", "Contact"];
    }
}

export function Page$reflection() {
    return union_type("Client.Page.Page", [], Page, () => [[], [], [], [], [], []]);
}

