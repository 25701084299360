import { Union, Record } from "../../.fable/fable-library.3.2.1/Types.js";
import { LargePhotoUrlPack$reflection } from "../../../Shared/Shared.fs.js";
import { union_type, record_type, bool_type } from "../../.fable/fable-library.3.2.1/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../.fable/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../.fable/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { Cmd_none, Cmd_OfPromise_either } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import * as react from "react";

export class Model extends Record {
    constructor(urls, previousUrls, mediumLoaded) {
        super();
        this.urls = urls;
        this.previousUrls = previousUrls;
        this.mediumLoaded = mediumLoaded;
    }
}

export function Model$reflection() {
    return record_type("Client.Pages.PhotosComponents.MediumDisplay.Model", [], Model, () => [["urls", LargePhotoUrlPack$reflection()], ["previousUrls", LargePhotoUrlPack$reflection()], ["mediumLoaded", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadComplete"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Pages.PhotosComponents.MediumDisplay.Msg", [], Msg, () => [[]]);
}

export function photoPromiseFromUrl(url) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const image = document.createElement("img");
        image.setAttribute("src", url);
        image.onload = ((_arg1) => Promise.resolve());
        if (image.complete) {
            void Promise.resolve();
            return Promise.resolve();
        }
        else {
            return Promise.resolve();
        }
    }));
}

export function init(urls, prevUrls) {
    return [new Model(urls, prevUrls, false), Cmd_OfPromise_either((url) => photoPromiseFromUrl(url), urls.mediumUrl, () => (new Msg(0)), (_arg2) => (new Msg(0)))];
}

export function update(msg, model) {
    return [new Model(model.urls, model.previousUrls, true), Cmd_none()];
}

export function view(model, dispatch) {
    if (model.mediumLoaded) {
        return react.createElement("a", {
            href: model.urls.fullUrl,
        }, react.createElement("img", {
            src: model.urls.mediumUrl,
        }));
    }
    else {
        return react.createElement("a", {
            href: model.previousUrls.fullUrl,
        }, react.createElement("img", {
            src: model.previousUrls.mediumUrl,
        }));
    }
}

