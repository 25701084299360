import { Union } from "../../fable-library.3.2.1/Types.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions__AddCaseName_1505, Reflection_getCaseName, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Size_ISize$reflection, Color_IColor$reflection } from "../Common.fs.js";
import { union_type, string_type, lambda_type, unit_type, list_type, class_type, bool_type } from "../../fable-library.3.2.1/Reflection.js";
import { DOMAttr, HTMLAttr } from "../../Fable.React.7.4.1/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.2.1/MapUtil.js";
import { singleton, empty, cons, map, exists } from "../../fable-library.3.2.1/List.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Color", "Size", "is-fullwidth", "is-link", "is-outlined", "is-inverted", "is-text", "is-ghost", "is-rounded", "is-expanded", "is-hovered", "is-focused", "is-active", "is-loading", "is-static", "is-light", "Disabled", "Props", "OnClick", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Button.Option", [], Option, () => [[["Item", Color_IColor$reflection()]], [["Item", Size_ISize$reflection()]], [], [], [], [], [], [], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function btnView(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let pattern_matching_result, state;
        switch (option.tag) {
            case 1: {
                pattern_matching_result = 1;
                break;
            }
            case 3:
            case 2:
            case 4:
            case 5:
            case 6:
            case 8:
            case 9:
            case 7:
            case 15: {
                pattern_matching_result = 2;
                break;
            }
            case 10: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 11: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 12: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 13: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 14: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 16: {
                pattern_matching_result = 4;
                break;
            }
            case 18: {
                pattern_matching_result = 5;
                break;
            }
            case 17: {
                pattern_matching_result = 6;
                break;
            }
            case 19: {
                pattern_matching_result = 7;
                break;
            }
            case 20: {
                pattern_matching_result = 8;
                break;
            }
            default: pattern_matching_result = 0}
        switch (pattern_matching_result) {
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
            case 4: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(79, option.fields[0]));
            }
            case 5: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, option.fields[0]));
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
        }
    }, "button"), element, children);
}

export function button(options, children) {
    return btnView((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export function span(options, children) {
    return btnView((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), options, children);
}

export function a(options, children) {
    return btnView((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Input_btnInput(typ, options) {
    if (exists((opts) => {
        if (opts.tag === 17) {
            return true;
        }
        else {
            return false;
        }
    }, options)) {
        return btnView((options_1, _arg1) => react.createElement("input", keyValueList(options_1, 1)), map((opts_1) => {
            if (opts_1.tag === 17) {
                return new Option(17, cons(new HTMLAttr(159, typ), opts_1.fields[0]));
            }
            else {
                return opts_1;
            }
        }, options), empty());
    }
    else {
        return btnView((options_2, _arg2) => react.createElement("input", keyValueList(options_2, 1)), cons(new Option(17, singleton(new HTMLAttr(159, typ))), options), empty());
    }
}

export function Input_reset(options) {
    return Input_btnInput("reset", options);
}

export function Input_submit(options) {
    return Input_btnInput("submit", options);
}

export class List_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-addons", "is-centered", "is-right", "are-small", "are-medium", "are-large", "Props", "CustomClass", "Modifiers"];
    }
}

export function List_Option$reflection() {
    return union_type("Fulma.Button.List.Option", [], List_Option, () => [[], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function list(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "buttons"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

