import { Union, Record } from "./.fable/fable-library.3.2.1/Types.js";
import { union_type, record_type, bool_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { Page, Page$reflection } from "./Page.fs.js";
import { Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { HTMLAttr, DOMAttr } from "./.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Start_div, Menu_Option, menu, Burger_Option, burger, Item_Option, Item_a, Brand_div, Option, navbar } from "./.fable/Fulma.2.14.1/Components/Navbar.fs.js";
import { Color_IColor } from "./.fable/Fulma.2.14.1/Common.fs.js";
import { empty, ofArray, singleton } from "./.fable/fable-library.3.2.1/List.js";
import { container } from "./.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import * as react from "react";
import { equals } from "./.fable/fable-library.3.2.1/Util.js";

export class Model extends Record {
    constructor(MobileExpanded, CurrentPage) {
        super();
        this.MobileExpanded = MobileExpanded;
        this.CurrentPage = CurrentPage;
    }
}

export function Model$reflection() {
    return record_type("Client.MeoNavBar.Model", [], Model, () => [["MobileExpanded", bool_type], ["CurrentPage", Page$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MobileToggleExpand"];
    }
}

export function Msg$reflection() {
    return union_type("Client.MeoNavBar.Msg", [], Msg, () => [[]]);
}

export function init(page) {
    return [new Model(false, page), Cmd_none()];
}

export function update(msg, model) {
    return [new Model(!model.MobileExpanded, model.CurrentPage), Cmd_none()];
}

export function onNavigate(model, dispatch, e) {
    e.target.blur();
    if (model.MobileExpanded) {
        dispatch(new Msg(0));
    }
}

export function view(model, dispatch) {
    const handleNavClick = new DOMAttr(40, (e) => {
        onNavigate(model, dispatch, e);
    });
    return navbar(ofArray([new Option(0, new Color_IColor(4)), new Option(6, singleton(["style", {
        background: "linear-gradient(to bottom,#3c3c3c 0,#222 100%)",
        backgroundSize: "cover",
        enableBackground: true,
    }]))]), singleton(container(empty(), ofArray([Brand_div(empty(), ofArray([Item_a(singleton(new Item_Option(5, singleton(new HTMLAttr(94, "https://safe-stack.github.io/")))), singleton(react.createElement("img", {
        src: "/favicon.png",
        alt: "Logo",
    }))), burger(ofArray([new Burger_Option(2, ofArray([["data-target", "meoNavBar"], new DOMAttr(40, (_arg1) => {
        dispatch(new Msg(0));
    })])), new Burger_Option(3, model.MobileExpanded ? "is-active" : "")]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})]))])), menu(singleton(new Menu_Option(1, model.MobileExpanded ? ofArray([new HTMLAttr(99, "meoNavBar"), new HTMLAttr(65, "isActive")]) : singleton(new HTMLAttr(99, "meoNavBar")))), singleton(Start_div(empty(), ofArray([Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#home"), handleNavClick])), new Item_Option(1, equals(new Page(0), model.CurrentPage))]), singleton("Home")), Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#about"), handleNavClick])), new Item_Option(1, equals(new Page(1), model.CurrentPage))]), singleton("About Us")), Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#photos"), handleNavClick])), new Item_Option(1, equals(new Page(2), model.CurrentPage))]), singleton("Photos")), Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#progress"), handleNavClick])), new Item_Option(1, equals(new Page(3), model.CurrentPage))]), singleton("Progress")), Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#donate"), handleNavClick])), new Item_Option(1, equals(new Page(4), model.CurrentPage))]), singleton("Donate")), Item_a(ofArray([new Item_Option(5, ofArray([new HTMLAttr(94, "#contact"), handleNavClick])), new Item_Option(1, equals(new Page(5), model.CurrentPage))]), singleton("Contact"))]))))]))));
}

